#google-map-container {
    width: 100%;
    height: 100%;
  }
  .google-map
  {
    width: 100%;
    height: 100%;
  }
.micro
{
  font-size:9px;
}
.small
{
  font-size:12px;
}
.micro-padding
{
  padding: 2px;
}
.micro-text
{
  font-weight: 600;
}
.micro-skinny
{
  font-weight: 100;
}
div.transparent-background
{
  background: rgba(0, 0,0, 0) !important;
  overflow:hidden !important;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
  -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
}
