.micro-text
{
    font-size: xx-small;
}
.margin-auto
{
    margin: auto;
}
.thumbnail {   
    object-fit:scale-down;
  }
